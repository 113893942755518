@import '~ngx-toastr/toastr-bs4-alert';

html {
  overflow: hidden;
}

html,
body {
  height: 100%;
}

* {
  min-height: 0;
  min-width: 0;
}

thead {
  background-color: #ddd;
}

.content {
  padding: 3rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;

  &>* {
    width: 100%;
  }
}

.content-panel,
.content-panel-overflow {
  background: #ffffff;
  box-shadow: 0px 3px 2px #aab2bd;
  min-height: initial;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  .table {
    margin-bottom: 0;

    th {
      border-top: none;
    }
  }
}

.content-panel-overflow {
  overflow: auto;
}

.modal-header {
  background-color: $secondary;
  color: #ffffff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  h4 {
    font-size: 1rem;
    font-weight: 500;
  }
}

.input-group-prepend select.form-control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.pointer {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
}

.grab {
  cursor: grab;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.summary-item {
  @extend .form-group;

  .label {
    @extend label;
  }
}

.title-bar {
  padding: 0.75rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #dee2e6;

  h4 {
    margin: 0;
  }
}

.actions-bar {
  padding: 0.75rem;
  display: flex;
  justify-content: flex-end;
  border-top: 2px solid #dee2e6;
}

.sortable {
  & {
    cursor: pointer;
  }

  fa-icon {
    margin-left: 1em;
  }
}

.searchBar {
  padding: 0.5em;
  display: flex;
  width: 50%;
  margin-left: 5%;

  input {
    flex: 1;
    margin-left: 1em;
    background: transparent;
    border: none;
    border-bottom: 1px solid #000000;

    &::placeholder {
      font-style: italic;
      ;
      opacity: 0.5;
    }
  }
}

tbody th {
  font-weight: 400;
}

td.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .btn {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
}

td ul {
  margin-bottom: 0;
  padding-inline-start: 20px;
}

.section-title {
  background-color: #5e6371;
  color: #eee;
}

.alert-block {
  max-width: 50vw;
  margin: 2em auto;

  display: flex;
  flex-direction: row;
  align-items: center;

  &>fa-icon {
    padding-right: 0.83em;
    text-align: center;
    box-sizing: content-box;
    font-size: 1.5em;
  }
}

.clickable-icon {
  cursor: pointer;
  font-size: 1.3em;
  margin: 0.3em;

  &:hover {
    filter: brightness(80%);
  }
}

.disabled {
  cursor: default;
  opacity: 0.7;
  pointer-events: none;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.placeholder,
*::placeholder,
.select-placeholder:not(:focus) {
  font-style: italic;
  color: rgba(0, 0, 0, 0.5)
}

.colon-after::after {
  content: ' : ';
}

.app-modal-1000px .modal-dialog {
  max-width: 90%;
}

.app-modal-80 .modal-dialog {
  max-width: 80%;
}

.app-modal-60 .modal-dialog {
  max-width: 60%;
}

.nav-item {
  font-size: 16px;
  font-weight: bold;
  //border-bottom: 4px solid #FFF;
}

.vin {
.nav-link {
  color: #000000;
}

.nav-link.active,
.nav-link:hover {
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-bottom: 4px solid #8549c4;
}
}

.anpp {

  .primary-text {
    color: #41905c !important;
  }

  .secondary-text {
    color: rgba(131, 187, 38, 1) !important;
  }

  .background-secondary {
    background: rgba(131, 187, 38, 1) !important;
  }

  .background-primary {
    background-color: #41905c !important;
  }

  .link-secondary:hover {
    color: #41905c !important;
  }

  .border-primary {
    border:1px solid #41905c;
  }

  .link-secondary {
    color: rgba(131, 187, 38, 1) !important;
  }

  input:focus, select:focus, .form-control:focus {
    border-color: rgba(131, 187, 38, 1) !important;
    box-shadow: 0px 0px 6px 0px rgba(131, 187, 38, 1) !important;
  }

  .btnprimary,
  .btnprimary:hover,
  .btnprimary.active,
  .btn-primary:hover,
  .btn-primary,
  .btn-primary.active {
    background: #a1c93a !important;
    border: 1px solid #a1c93a !important;
  }

  
  .btn-outline-primary {  
    color: #a1c93a !important;
    border-color: #a1c93a !important;
  }
    
  .btn-outline-primary:hover {  
    color: #fff !important; 
    background: #a1c93a !important;
    border: 1px solid #a1c93a !important;
  }

  //Dashboard
  .gloabl-font-size {
    font-size: 0.9em;
  }

  .search-bloc-height {
    height: 312px;
  }

  .users-icon {
    font-size: 50px;
    color: #fff;
  }

  .users-number {
    font-size: 30px;
    color: #fff;
  }

  .user-title {
    margin-top: -5px;
    color: #fff;
  }

  .users-search-button {
    border-color: #CCC;
    color: #fff;
    background-color: #fff !important;
    border-radius: 0.25rem;
  }

  .users-search-icon {
    color: #0b004a;
  }

  .echarts-card {
    background: rgba(131, 187, 38, 0.1) !important;
  }

  .echarts-height {
    height: 272px;
  }

  .echarts-legend {
    font-size: 21px;
  }

  .border-radius {
    border-radius: 25px;
  }

  .cards {
    box-shadow: -2px 3px 37px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: -2px 3px 37px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 3px 37px 0px rgba(0, 0, 0, 0.1);
    background: #f8faff;
  }

  .badge-green {
    background: rgba(131, 187, 38, 0.15);
    color: rgba(131, 187, 38, 1);
  }

  .badge-green-white {
    background: rgba(131, 187, 38, 0.9);
    color: rgba(255, 255, 255, 1);
  }

  .nav-item {
    font-size: 16px;
    font-weight: bold;
    //border-bottom: 4px solid #FFF;
  }

  .nav-link {
    color: #000000;
  }

  .nav-link.active,
  .nav-link:hover {
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-bottom: 4px solid rgba(131, 187, 38, 1);
  }

  .border-radius-onglet {
    border-radius: 0 25px 25px 25px;
  }

  h4 {
    color: black;
  }

  thead {
    background-color: white !important;
    color: black !important;
  }

  .bg-td {
    background: rgba(131, 187, 38, 0.15);
  }

  .table thead th {
    border: 0px solid #dee2e6;
  }

  table th.bar1 {
    border: none !important;
    text-transform: uppercase;
    padding: 0;
  }

  .dropdown-item.active, .dropdown-item:active, .dropdown-item:focus {
    background-color: rgba(131, 187, 38, 1) !important;
    border-color: rgba(131, 187, 38, 1) !important;
  }

  .badge-green {
    background: rgba(131,187,38,0.15) !important;
    color: rgba(131,187,38,1) !important;
  }

  button:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
    box-shadow: 0px 0px 6px 0px rgba(131, 187, 38, 1);
  }

  .modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    background-color: white;
  }
  
  .modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
  }
  
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-weight: bold;
    color: #444;
  }

  .btn-link {
    color: rgba(131,187,38,1) !important;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #a1c93a;
    background-color: #a1c93a;
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #a1c93a;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0px 0px 6px 0px rgba(131, 187, 38, 1);
  }

  .lienMain { 
    color: #0b004a;
    text-decoration: underline;
    cursor: pointer;
  }

  .spacing {
    margin-top: 1em;
    padding-right: 2em;
  }

  .dashboard-main-div {
    height: 93%;
    overflow: auto;
    padding-bottom: 30px;
  }

  table {
    margin-bottom: 5rem;
  }

  .tableScroll {
    overflow-y: auto;
    display: flex;
  }

  .tableScroll-param-height {
    max-height: 85vh !important;
  }

  @media (min-height: 970px) {
    .tableScroll {
      max-height: 70vh;
    }
  }

  @media (max-height: 969px) {
    .tableScroll {
      max-height: 65vh;
    }
  }

  @media (max-height: 849px) {
    .tableScroll {
      max-height: 55vh;
    }
  }

}

.primary-text {
  color: #9966ce !important;
}

.btnprimary,
.btnprimary:hover,
.btnprimary.active,
.btn-primary:hover,
.btn-primary,
.btn-primary.active {
  background: #9966ce !important;
  border: 1px solid #9966ce !important;
}

.secondary-text {
  color: #261d56 !important;
}

.background-secondary {
  background: #261d56 !important;
}

.background-primary {
  background-color: #261d56 !important;
}

.border-primary {
  border:1px solid #261d56;
}

.link-secondary:hover {
  color: #8549c4 !important;
}

.link-secondary {
  color: #ac83d7 !important;
}


.app-modal-1001px .modal-dialog {
  max-width: 1340px;
}

.no-indicators .carousel-indicators {
  display: none;
}

ngb-typeahead-window {
  overflow: auto;
  max-height: 600px;
  z-index: 10000 !important;
}